import React from 'react'
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DataTable from "react-data-table-component";


export default function TableLoader({ columns }) {
    
    return (<table className="table">
        <tbody>
            <SkeletonTheme
                baseColor="#e5e7eb"
                highlightColor="#e5e7eb"
                borderRadius="0.5rem"
            >
                <tr>
                    {columns.map((u , i) => (
                        <td key={i+"1"}><Skeleton /></td>))
                    }
                </tr>
                <tr>
                    {columns.map((u ,i ) => (
                        <td key={i+"2"}><Skeleton /></td>))
                    }
                </tr>
                <tr>
                    {columns.map((u , i) => (
                        <td key={i+"3"}><Skeleton /></td>))
                    }
                </tr>
                <tr>
                    {columns.map((u, i) => (
                        <td><Skeleton key={i+"4"}/></td>))
                    }
                </tr>
            </SkeletonTheme>
        </tbody>
    </table>)
}
