import React from 'react'
import { Button, Spinner } from "react-bootstrap";
export default function ButtonLoading({ loading , innerText }) {

    if (loading) {
        return (<Button type="button" className="btn btn-primary">
            <Spinner size="sm" animation="border" /> <span className="ms-2">Loading...</span>
        </Button>)
    }else{
        return (<Button type="submit" className="btn btn-primary">
            {innerText}
      </Button>)
    }
}
