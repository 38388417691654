import { Breadcrumb, Container, FloatingLabel, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HomeIcon from "../../../assets/Images/DashboardIcon/home.svg";

function InPageNav({ pageNav }) {

  const navigate = useNavigate();

  return (
    <Container fluid={true} className="p-0 main-top-section d-flex justify-content-between">
      <Breadcrumb className="align-items-center d-flex h-100">
        <Breadcrumb.Item onClick={() => navigate('/')} className="align-items-center d-flex">
          <img src={HomeIcon} alt="" />
        </Breadcrumb.Item>
        {pageNav.map((page_item , index) => {
          return (<Breadcrumb.Item key={index} active={pageNav.active} onClick={() => navigate(page_item.link)} className="align-items-center d-flex">
            {page_item.name}
          </Breadcrumb.Item>)
        })}
      </Breadcrumb>



    </Container>
  );
}

export default InPageNav;
