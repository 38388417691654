import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import Select from 'react-select';

export default function CustomSelect({
  multiple = false,
  disabled = false,
  defaultLable,
  mainDivClasses = null,
  data = null,
  onChange,
  validateError = "",
  selectedValue = "",
  validationClass = null
}) {
  let defaultSelectedValue :any =multiple ? [] :  ""
  if (data && selectedValue) {

    if(multiple){
      let  myArray = selectedValue.split(",");
      data.forEach(array_item => {
        if (myArray.includes(array_item.value)) {
          defaultSelectedValue = [...defaultSelectedValue , array_item]
        }
      })
    }else{
      data.forEach(array_item => {
        if (array_item.value === selectedValue) {
          defaultSelectedValue = array_item
        }
      })
    }
  }
  return (

    <div className={mainDivClasses ? mainDivClasses : "col-12 col-md-6 col-lg-6 mb-3 custom-main-select"}>
      {/* <FloatingLabel label={`${defaultLable}`}> */}
      <Select
        className="custom-react-select"
        classNamePrefix="custom-react-select"
        placeholder={defaultLable}
        isMulti={multiple}
        isDisabled={disabled}
        value={selectedValue ? defaultSelectedValue : ""}
        onChange={(item: any) => {
          if(multiple){
            onChange(item);
          }else{
            onChange(item.value);
          }
        }}
        options={[{ value: '', label: defaultLable }   , ...data]}
      />
       {validateError && <span className={`text-danger position-relative ${validationClass ? validationClass :""}`}>{validateError}</span>}
      {/* <Form.Select
          multiple={multiple}
          disabled={disabled}
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
        >
          <option selected={selectedValue === "" ? true : false} value="">
            {defaultLable}
          </option>
          {data
            ? data.map((item: any, index) => {
                return (
                  <option
                    association_type_term={
                      " " in item ? item.association_type_term : ""
                    }
                    id={item.value}
                    selected={item.value === selectedValue ? true : false}
                    key={index}
                    value={item.value}
                  >
                    {item.label}
                  </option>
                );
              })
            : null}
        </Form.Select> */}
     
      {/* </FloatingLabel> */}
    </div>
  );
}
