import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./assets/styles/App.scss";
import "./assets/styles/Component.scss";
import Loader from "./Component/Loader/Index";
import DefaultLayout from "./Container/DefaultLayout";
import AuthLayout from "./Views/Authentication/AuthLayout";
import AuthLogin from "./Views/Authentication/AuthLogin";
import ForgotPass from "./Views/Authentication/ForgotPass";
import ResetPass from "./Views/Authentication/ResetPass";

import { ToastContainer } from 'react-toastify';
import { useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import APICall from "./networking/AxiousServices";
import ApiTypes from "./networking/APItypes";
import ClaimProfile from "./Views/pages/claimprofile/ClaimProfile";

// import GeneralSetting from "./Views/Settings/GeneralSetting";
const EditProfile = React.lazy(() => import("./Views/pages/profile/Index"));
const GeneralSetting = React.lazy(() => import("./Views/Settings/GeneralSetting"));
const HomePage = React.lazy(() => import("./Views/Index"));
const ComponentsPage = React.lazy(() => import("./Views/ComponentsPage"));
const Users = React.lazy(() => import("./Views/Settings/User"));
const EditUser = React.lazy(() => import("./Views/Settings/User/Edit"));
const AddUser = React.lazy(() => import("./Views/Settings/User/Add"));
const RoleList = React.lazy(() => import("./Views/Settings/Role"));
const AddRole = React.lazy(() => import("./Views/Settings/Role/AddRole"));
const EditRole = React.lazy(() => import("./Views/Settings/Role/EditRole"));
const ArtistList = React.lazy(() => import("./Views/pages/artist"));
const AddArtist = React.lazy(() => import("./Views/pages/artist/Add"));
const EditArtist = React.lazy(() => import("./Views/pages/artist/Edit"));
const ViewArtist = React.lazy(() => import("./Views/pages/artist/View"));
const VideoList = React.lazy(() => import("./Views/pages/video"));
const AddVideo = React.lazy(() => import("./Views/pages/video/Add"));
const EditVideo = React.lazy(() => import("./Views/pages/video/Edit"));
const ViewVideo = React.lazy(() => import("./Views/pages/video/View"));
const ClientList = React.lazy(() => import("./Views/pages/client"));
const ViewClient = React.lazy(() => import("./Views/pages/client/View"));
const AwardList = React.lazy(() => import("./Views/pages/award"));
const AddAward = React.lazy(() => import("./Views/pages/award/Add"));
const EditAward = React.lazy(() => import("./Views/pages/award/Edit"));
const ViewAward = React.lazy(() => import("./Views/pages/award/View"));
const WebSetting = React.lazy(() => import("./Views/Settings/WebSetting"));
const SuggestionList = React.lazy(() => import("./Views/pages/suggestion"));
const NotificationPage = React.lazy(() => import("./Views/pages/notification/NotificationPage"));
const FaqList = React.lazy(() => import("./Views/pages/faq/FaqList"));
const AddFaq = React.lazy(() => import("./Views/pages/faq/AddFaq"));
const EditFaq = React.lazy(() => import("./Views/pages/faq/EditFaq"));

function App() {

  // const fetchRoles = async ()=>{
  //   let res = await  APICall({url : ApiTypes.fetchUserRoles})
  // }
  // useEffect(() => {
  //   fetchRoles()
  // }, [])

  const UserData = useSelector((state: any) => state.data);
  const authToken = UserData.auth_token;

  const appRoute = () => (<>
    <Route path="/" element={<DefaultLayout />}>
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route path="/" element={
        <React.Suspense fallback={<Loader />}>
          <HomePage />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/componentPage"
        element={
          <React.Suspense fallback={<Loader />}>
            <ComponentsPage />
          </React.Suspense>
        }
      >
      </Route>
      <Route path="/edit/profile" element={
        <React.Suspense fallback={<Loader />}>
          <EditProfile />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/general_setting" element={
        <React.Suspense fallback={<Loader />}>
          <GeneralSetting />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/manage_role" element={
        <React.Suspense fallback={<Loader />}>
          <RoleList />
        </React.Suspense>
      }
      >
      </Route>

      <Route path="/add/role" element={
        <React.Suspense fallback={<Loader />}>
          <AddRole />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/edit/role/:role_id" element={
        <React.Suspense fallback={<Loader />}>
          <EditRole />
        </React.Suspense>
      }
      >
      </Route>

      <Route path="/users" element={
        <React.Suspense fallback={<Loader />}>
          <Users />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/add/user" element={
        <React.Suspense fallback={<Loader />}>
          <AddUser />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/edit/user/:user_id" element={
        <React.Suspense fallback={<Loader />}>
          <EditUser />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/artists" element={
        <React.Suspense fallback={<Loader />}>
          <ArtistList />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/add/artist" element={
        <React.Suspense fallback={<Loader />}>
          <AddArtist />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/edit/artist/:artist_id" element={
        <React.Suspense fallback={<Loader />}>
          <EditArtist />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/view/artist/:artist_id" element={
        <React.Suspense fallback={<Loader />}>
          <ViewArtist />
        </React.Suspense>
      }
      >
      </Route>

      <Route path="/videos" element={
        <React.Suspense fallback={<Loader />}>
          <VideoList />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/add/video" element={
        <React.Suspense fallback={<Loader />}>
          <AddVideo />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/edit/video/:video_id" element={
        <React.Suspense fallback={<Loader />}>
          <EditVideo />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/view/video/:video_id" element={
        <React.Suspense fallback={<Loader />}>
          <ViewVideo />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/clients" element={
        <React.Suspense fallback={<Loader />}>
          <ClientList />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/view/client/:client_id" element={
        <React.Suspense fallback={<Loader />}>
          <ViewClient />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/awards" element={
        <React.Suspense fallback={<Loader />}>
          <AwardList />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/add/award" element={
        <React.Suspense fallback={<Loader />}>
          <AddAward />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/edit/award/:award_id" element={
        <React.Suspense fallback={<Loader />}>
          <EditAward />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/view/award/:award_id" element={
        <React.Suspense fallback={<Loader />}>
          <ViewAward />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/web_setting" element={
        <React.Suspense fallback={<Loader />}>
          <WebSetting />
        </React.Suspense>
      }
      >
      </Route>
      <Route path="/suggestions" element={
        <React.Suspense fallback={<Loader />}>
          <SuggestionList />
        </React.Suspense>
      }
      >
      </Route>

      <Route path="/notifications"
        element={
          <React.Suspense fallback={<Loader />}>
            <NotificationPage />
          </React.Suspense>
        }
      ></Route>

      <Route path="/claim_profile"
        element={
          <React.Suspense fallback={<Loader />}>
            <ClaimProfile />
          </React.Suspense>
        }
      ></Route>

      <Route path="/faq"
        element={
          <React.Suspense fallback={<Loader />}>
            <FaqList/>
          </React.Suspense>
        }
      ></Route>

      <Route path="/add/faq"
        element={
          <React.Suspense fallback={<Loader />}>
            <AddFaq />
          </React.Suspense>
        }
      ></Route>

      <Route path="/edit/faq/:faq_id"
        element={
          <React.Suspense fallback={<Loader />}>
            <EditFaq/>
          </React.Suspense>
        }
      ></Route>

    </Route>
  </>)

  const authRoute = () => (<>
    <Route path="*" element={<Navigate to="/" replace />} />
    <Route path="/" element={<AuthLayout PageComponent={AuthLogin} />}></Route>
    <Route path="/forgot-Password" element={<AuthLayout PageComponent={ForgotPass} />}></Route>
    <Route path="/reset/password/:email_token" element={<AuthLayout PageComponent={ResetPass} />}></Route>
  </>)

  return (
    <div>
      <Router>
        <Routes>
          {authToken ? appRoute() : authRoute()}
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
