import React, { useState } from 'react'
import { FloatingLabel, Form } from "react-bootstrap";
import { ReactComponent as EyeIcon } from "../../assets/Images/Icons/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/Images/Icons/eyeSlash.svg";
import { Enums } from '../../constants/Enums';

export default function InputText({isBeforeAward=true  ,  isTillCurrent = true, disabled = false, value, type, placeholder, validateError = "", onChange, mainDivClasses = null }) {

    const [passHide, setPassHide] = useState(true);

    let hideShowIcon = false;

    if (type === "password") {

        hideShowIcon = true;
    }

  

    return (<div className={mainDivClasses ? mainDivClasses : "col-12 col-md-6 col-lg-6 mb-3 "}>
        <FloatingLabel controlId="floatingInput" label={placeholder}>
            <Form.Control
                disabled={disabled}
                autoComplete='off'
                value={value}
                onWheelCapture={(e: any) => {
                    if (type == 'number')
                        e.target.blur()
                }}
                // max={isTillCurrent ? new Date().toLocaleDateString('en-ca') : new Date(new Date().getFullYear() + Enums.award_after_years, new Date().getMonth(), new Date().getDate()).toLocaleDateString('en-ca')}
                max={isTillCurrent ? new Date().toLocaleDateString('en-ca') : ""}
                min={isBeforeAward ? "" : new Date(new Date().getFullYear() - Enums.award_previous_years, new Date().getMonth(), new Date().getDate()).toLocaleDateString('en-ca')}
                onChange={(e: any) => {
                    if (type == 'number') {
                        if (e.target.value) {
                            if (e.target.value >= 0) {
                                onChange(e.target.value)
                            }
                        } else {
                            onChange("")
                        }

                    } else {
                        if (e.target.value) {
                            onChange(e.target.value)
                        } else {
                            onChange("")
                        }
                    }

                }} type={hideShowIcon ? passHide ? 'password' : 'text' : type} placeholder={placeholder} />
            {hideShowIcon ? passHide ?
                <EyeIcon onClick={() => setPassHide(false)} style={{ marginTop: "10px" }} className="cursor-pointer position-absolute me-3 end-0 top-0" />
                :
                <EyeSlashIcon onClick={() => setPassHide(true)} style={{ marginTop: "10px" }} className="cursor-pointer position-absolute me-3 end-0 top-0" />
                : ""}
            {validateError ? <span className='text-danger'>{validateError}</span> : null}
        </FloatingLabel>
    </div>)
}
