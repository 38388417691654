/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as AccountIcon } from "../assets/Images/Icons/sidebarIcons/accounts.svg";
import { ReactComponent as ActivitiesIcon } from "../assets/Images/Icons/sidebarIcons/activities.svg";
import { ReactComponent as CollapseIcon } from "../assets/Images/Icons/sidebarIcons/arrowRight.svg";
import { ReactComponent as ContactsIcon } from "../assets/Images/Icons/sidebarIcons/contacts.svg";
import { ReactComponent as DashboardIcon } from "../assets/Images/Icons/sidebarIcons/dashboard.svg";
import { ReactComponent as MiscallaneousIcon } from "../assets/Images/Icons/sidebarIcons/miscallaneous.svg";
import { ReactComponent as OpportunityIcon } from "../assets/Images/Icons/sidebarIcons/opportunity.svg";
import { ReactComponent as ReportsIcon } from "../assets/Images/Icons/sidebarIcons/reports.svg";
import { ReactComponent as SetupConfigurationIcon } from "../assets/Images/Icons/sidebarIcons/setup-configuration.svg";
import { ReactComponent as SupportIcon } from "../assets/Images/Icons/sidebarIcons/supportIcon.svg";
import { ReactComponent as SuspectsIcon } from "../assets/Images/Icons/sidebarIcons/suspects.svg";
import ApiTypes from "../networking/APItypes";
import { useSelector } from "react-redux";
import APICall from "../networking/AxiousServices";
import ReactHtmlParser from 'react-html-parser';

function DefaultSidebar() {
  const userRoles = useSelector((state: any) => state.data.user_roles);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const navigate = useNavigate();
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const routedActivation = {
    dashboard: ["/"],
    setting: ["/web_setting"],
    setup: ["/manage_role", "/add/role", "/edit/role/:id", "/users", "/add/user", "/edit/user/:id","/general_setting"],
    component: ["/componentPage"],
    artist : ["/artists","/add/artist" , "/edit/artist/:id" ,"/view/artist/:id"],
    video : ["/videos","/add/video" , "/edit/video/:id" ,"/view/video/:id"],
    client :  ["/clients" ,"/view/client/:id"],
    award :  ["/awards" ,"/add/award" ,"/edit/award/:id","/view/award/:id"],
    suggestion :  ["/suggestions"],
    claim_profile :["/claim_profile"],
    faq :["/faq",'/add/faq' ,'/edit/faq/:id']
  };

  const setActiveFun = () => {
    const routeKeys = Object.keys(routedActivation);
    const childClass = document.getElementsByClassName("sub-menu-active");
    const menuActiveClass = document.getElementsByClassName("menuActive");
    if (menuActiveClass.length) {
      menuActiveClass[0].classList.remove("menuActive");
    }
    if (childClass.length) {
      childClass[0].classList.remove("sub-menu-active");
    }
    routeKeys.forEach((routeitem) => {
      if (routedActivation[routeitem].includes(location.pathname)) {
        const parentDiv = document.getElementById(`${routeitem}`);
        if (parentDiv?.classList.contains("main-menu-title")) {
          const proInnerItem = parentDiv.getElementsByClassName("pro-inner-item");
          proInnerItem[0].classList.add("sub-menu-active");
        } else {
          if (parentDiv != null) {
            parentDiv.classList.add("sub-menu-active");
          }
        }
        return false;
      } else {
        let newArray: any = [];
        let isMayAvailable = false
        let regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        location.pathname.split("/").forEach((string) => {
          if (regexExp.test(string)) {
            newArray = [...newArray, ':id'];
            isMayAvailable = true
          } else {
            newArray = [...newArray, string]
          }
        })
        let newString = newArray.join("/");
        if (routedActivation[routeitem].includes(newString)) {
          const parentDiv = document.getElementById(`${routeitem}`);
          if (parentDiv?.classList.contains("main-menu-title")) {
            const proInnerItem = parentDiv.getElementsByClassName("pro-inner-item");
            proInnerItem[0].classList.add("sub-menu-active");
          } else {
            if (parentDiv != null) {
              parentDiv.classList.add("sub-menu-active");
            }
          }
          return false;
        }
      }
    });
  };

  useEffect(() => {
    setActiveFun();
  });

  return (
    <>
      <ProSidebar collapsed={menuCollapse}>
        <SidebarHeader>
          <div className="closemenu" role="button" tabIndex={0} onClick={menuIconClick} onKeyDown={menuIconClick} >
            <CollapseIcon />
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu>
            {userRoles && userRoles.map((userRole, index) => {
              if (userRole.childs) {
                return (<SubMenu
                  key={userRole.parent.right_id}
                  id={userRole.parent.form_name}
                  className="main-menu-title"
                  icon={ReactHtmlParser(userRole.parent.form_icon)}
                  title={userRole.parent.form_detail}
                >
                  {userRole.childs.map((child) => {
                    return (<NavLink
                      key={child.right_id}
                      onClick={(e) => {
                        navigate(child.page_url);
                        setActiveFun();
                      }}
                      to={child.page_url}
                    >
                      <MenuItem>{child.form_detail}</MenuItem>
                    </NavLink>)
                  })}

                </SubMenu>)
              } else {
                return (<NavLink
                  id={userRole.parent.form_name}
                  to={userRole.parent.page_url}
                  onClick={(e) => {
                    navigate(userRole.parent.page_url);
                    setActiveFun();
                  }}
                >
                  <MenuItem icon={ReactHtmlParser(userRole.parent.form_icon)}>{userRole.parent.form_detail}</MenuItem>
                </NavLink>)
              }
            })}


            {/* <NavLink
              id="dashboard"
              to="/"
              onClick={(e) => {
                navigate("/");
                setActiveFun();
              }}
            >
              <MenuItem icon={<DashboardIcon />}>Dashboard</MenuItem>
            </NavLink>
            <SubMenu
              id="setup"
              className="main-menu-title"
              icon={<MiscallaneousIcon />}
              title="Setup"
            >
              <NavLink
                onClick={(e) => {
                  navigate("/general_setting");
                  setActiveFun();
                }}
                to="/general_setting"
              >
                <MenuItem>General Setting</MenuItem>
              </NavLink>
              <NavLink
                onClick={(e) => {
                  navigate("/manage_role");
                  setActiveFun();
                }}
                to="/manage_role"
              >
                <MenuItem>Manage Role</MenuItem>
              </NavLink>
              <NavLink
                onClick={(e) => {
                  navigate("/users");
                  setActiveFun();
                }}
                to="/users"
              >
                <MenuItem>User Management</MenuItem>
              </NavLink>
            </SubMenu> */}






          </Menu>



        </SidebarContent>
        {/* <SidebarFooter>
          <div className="footer-support">
            <SupportIcon />
          </div>
        </SidebarFooter> */}
      </ProSidebar>
    </>
  );
}

export default DefaultSidebar;
