import LoginButtonIcon from "../../../src/assets/Images/Icons/LoginButtonIcon.svg";
import { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LoginUserIcon from "../../../src/assets/Images/Icons/LoginUserIcon.svg";
import { alerts } from "../../common/alertService";
import { commonHelper } from "../../common/commonHelper";
import AuthInputText from "../../Component/Form/AuthInputText";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";
import { useDispatch } from "react-redux";
function ForgotPass() {

  document.title = process.env.REACT_APP_NAME + ' | ' + 'Forgot Password';

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const [formInputs, setFormInputs] = useState<any>({
    username: "",
  })

  const [formInputsErrors, setFormInputsErrors] = useState<any>({
    username: "",
  })

  const validationError = {
    username: "Username field is required.",
    validate_email: "Email is not valid",
  }

  const validateForm = () => {
    let errorJson = {};
    let isValidate = true;

    Object.keys(formInputsErrors).forEach((form_er_key) => {
      if (formInputs[form_er_key] === "") {
        errorJson = {
          ...errorJson,
          [form_er_key]: validationError[form_er_key]
        }
        isValidate = false;
      }
      if (form_er_key === "username") {
        if (formInputs[form_er_key] !== "") {
          if (!pattern.test(formInputs.username)) {
            errorJson = {
              ...errorJson,
              [form_er_key]: validationError.validate_email
            }
            isValidate = false;
          }
        }
      }
    })
    setFormInputsErrors(errorJson)
    return isValidate;
  }
  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (validateForm()) {

      setLoading(true)
      let res = await APICall({
        url: ApiTypes.sendResetLink,
        data: formInputs
      })
      if (res) {
        if (res.status === 1) {
          setLoading(false)
          alerts.notifySuccess(res.message)
        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <div className="login-title"> Forgot Password </div>
      <p className="login-description">
        {/* Enter your email  */}
      </p>
      <Form onSubmit={(e) => handleForgotPassword(e)}>
        <AuthInputText
          value={formInputs.username}
          onChange={(value) => {
            setFormInputs({
              ...formInputs,
              username: value
            })
          }}
          inputIcon={LoginUserIcon}
          type="text"
          placeholder="Enter Registered E-mail"
          validateError={formInputsErrors.username}
        />
        <Form.Group className="form-button d-flex ">
          {!loading ?
            <button className="btn btn-primary" type="submit">
              <span className="me-3">
                <img src={LoginButtonIcon} alt="" />
              </span>
              Get Password
            </button>
            :
            <Button type="button" className="btn btn-primary">
              <Spinner size="sm" animation="border" /> <span className="ms-2">Loading...</span>
            </Button>
          }
          <Link className="btn btn-btn-secondary" to="/">
            <span className="me-3">
              <img src={LoginButtonIcon} alt="" />
            </span>
            Cancel
          </Link>
        </Form.Group >
      </Form>
    </>
  );
}

export default ForgotPass;
