import BlueLabel from "../Component/Labels/BlueLabel/Index";
import GreenLabel from "../Component/Labels/GreenLabel/Index";
import RedLabel from "../Component/Labels/RedLabel/Index";

export const Enums = {
    TermCategory: {
        user_type: 'user_type',
        role_type: 'role_type',
        artist_type: 'artist_type',
        video_category_type :'video_category_type'
    },
    RespectTitleOptions: [
        { label: "Mr.", value: 'Mr.' },
        { label: "Mrs.", value: "Mrs." },
        { label: "Ms.", value: "Ms." },
        { label: "Miss", value: 'Miss' }],
    GenderTypes: {
        gender_male: 'Male',
        gender_female: 'Female',
        gender_other: 'Other'

    },
    SocialTypeTerms: {
        facebook: 'social_facebook',
        instagram: 'social_instagram',
        linkedin: 'social_linkedin',
        website: 'social_website'
    },
    AwardTypeTerm: {
        award_artist: 'award_artist',
        award_video: 'award_video'
    },
    AwardTypes: {
        award_artist: 'Artist',
        award_video: 'Video',
    },
    NomineeTypes: {
        artist: 'Artist',
        video: 'Video',
    },
    SuggestionStatus: {
        pending: 'Pending',
        accepted: 'Accepted',
        rejected: 'Rejected',
    },
    SuggestionStatusEnum: {
        pending: 'pending',
        accepted: 'accepted',
        rejected: 'rejected',
    },
    SuggestionStatusColor: {
        pending: <BlueLabel blueLabel={'Pending'} />,
        accepted: <GreenLabel successLabel={'Accepted'} />,
        rejected: <RedLabel dangerLabel={'Rejected'} />,
    },
    SuggestionStatusOptions: [
        // { label: "Pending", value: 'pending' },
        { label: "Accepted", value: "accepted" },
        { label: "Rejected", value: "rejected" },
    ],
    ClaimStatusColor: {
        pending: <BlueLabel blueLabel={'Pending'} />,
        approve: <GreenLabel successLabel={'Approved'} />,
        rejected: <RedLabel dangerLabel={'Rejected'} />,
    },
    ClaimStatusOptions: [
        { label: "Pending", value: 'pending' },
        { label: "Approved", value: "approve" },
        { label: "Rejected", value: "rejected" },
    ],
    AddCounts: 30,
    award_previous_years: 50,
    award_after_years: 0
};
