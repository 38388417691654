const BaseURL = process.env.REACT_APP_API_URL;
const RootURL = process.env.REACT_APP_ROOT_URL;


const ApiTypes = {
  //Options 
  fetchTermCategoryTypes: RootURL + "options/term_category",
  fetchGenderTerms: RootURL + "options/gender",
  fetchSocialLinksOptions: RootURL + "options/social_links",
  fetchArtistTypesOptions: RootURL + "options/artist_types",
  fetchTermListByCategory: RootURL + 'options/terms_list_by_category_id',
  fetchVideoCategoryOptions: RootURL + "options/video_category_types",
  fetchSubCategoryOptions: RootURL + 'options/video_sub_category_types',
  fetchArtistByTypes: RootURL + 'options/artists_by_type',
  fetchVideoTypes: RootURL + 'options/video_types',
  fetchAwardTypes: RootURL + 'options/get_award_category_type_list',
  fetchAwardSubTypes: RootURL + 'options/get_award_sub_category_types',
  fetchProClients: RootURL + 'options/get_pro_clients',
  fetchVideoAssociations : RootURL + 'options/get_video_association_list',
  fetchVideoSocialLinksOptions: RootURL + "options/video_social_links",

  //Auth
  login: BaseURL + "login",
  ProfileDetails: BaseURL + "get_profile_details",
  sendResetLink: BaseURL + "send_reset_pass_link",
  checkResetToken: BaseURL + "check_reset_token",
  resetPassword: BaseURL + "reset_password",

  //Dashboard

  fetchDashboardDetails : BaseURL + "dashboard/get_dashboard_details",
  //Profile
  changePassword: BaseURL + "change_password",
  updateProfile: BaseURL + "update_profile_details",

  //Roles and Rights And Settings
  fetchTerms: BaseURL + "get_terms",
  fetchTerm: BaseURL + "get_term_detail",
  addUpdateTerm: BaseURL + "add_update_term_detail",
  userTypeStatusUpdate: BaseURL + "update_term_status",
  fetchCategoryTypesOptions: BaseURL + "get_category_types_options",
  fetchRoles: BaseURL + "get_roles",
  fetchRights: BaseURL + "get_rights",
  addRole: BaseURL + "add_role_and_rights",
  updateRole: BaseURL + "update_role_and_rights",
  fetchRoleRightsDetails: BaseURL + "get_role_rights_details",
  roleStatusUpdate: BaseURL + "update_role_status",
  fetchRolesOption: BaseURL + "get_roles_options",
  fetchUserRoles: BaseURL + "get_user_role_rights",

  //User Management
  addUser: BaseURL + "user/add",
  updateUser: BaseURL + "user/update",
  userList: BaseURL + "user/list",
  userUpdateStatus: BaseURL + "user/update_status",
  fetchUserDetails: BaseURL + "user/get_details",

  //Artist
  saveArtist: BaseURL + "artist/save",
  artistList: BaseURL + "artist/list",
  artistUpdateStatus: BaseURL + "artist/update_status",
  fetchArtistDetails: BaseURL + "artist/get_details",
  makeArtistAsClientUser: BaseURL + 'artist/make_artist_as_client_user',


  //Video
  saveVideo: BaseURL + "video/save",
  videoList: BaseURL + "video/list",
  videoUpdateStatus: BaseURL + "video/update_status",
  fetchVideoDetails: BaseURL + "video/get_details",

  //Client
  clientList: BaseURL + "client/list",
  clientUpdateStatus: BaseURL + "client/update_client_status",
  fetchClientDetails: BaseURL + "client/client_details",


  //Video
  saveAward: BaseURL + "award/save_award",
  awardList: BaseURL + "award/get_award_list",
  awardUpdateStatus: BaseURL + "award/update_award_status",
  fetchAwardDetails: BaseURL + "award/get_award_details",
  searchNominee: BaseURL  + "award/search_nominee",
  fetchAwardNominees : BaseURL + "award/get_award_nominee_by_award_id",


  //Web Setting
  fetchWebSetting :  BaseURL + "settings/get_web_setting",
  updateWebSetting : BaseURL + "settings/update_web_setting",

  //Suggestion List
  suggestionList : BaseURL + "suggestion/suggestion_list",
  changeSuggestionStatus : BaseURL + "suggestion/suggestion_status_update",
  videoArtistTypeAssociations :  BaseURL + "suggestion/artist_type_video_associations",
  acceptSuggestionStatus :  BaseURL + "suggestion/accept_suggestion_status",

  //Notification
  unreadNotifications: BaseURL + "get_unread_notifications",
  allNotifications: BaseURL + "get_notifications",
  markAsReadNotifications: BaseURL + "mark_as_read_notifications",

  //Claim Profile
  fetchClaimProfileList :  BaseURL + "claim_request/get_claim_request_list",
  changeClaimStatus :  BaseURL + "claim_request/update_claim_request_status",

  //FAQ
  fetchFaqList : BaseURL + "faq/list" ,
  fetchFaqDetails : BaseURL + "faq/details" ,
  saveFaq : BaseURL + "faq/save" ,
  faqUpdateStatus : BaseURL + "faq/update_faq_status" ,
};
export default ApiTypes;