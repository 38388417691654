import React, { useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { ReactComponent as EyeIcon } from "../../assets/Images/Icons/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/Images/Icons/eyeSlash.svg";

export default function AuthInputText({ value, inputGroupClass = null, inputIcon = null, type, placeholder = "", onChange, validateError }) {

  const [passHide, setPassHide] = useState(true);

  let hideShowIcon = false;

  if (type === "password") {

    hideShowIcon = true;
  }

  return (<div className="mb-3">
    <InputGroup className={`${inputGroupClass && inputGroupClass} position-relative`}>
      {inputIcon && <InputGroup.Text id="basic-addon1">
        <span>
          <img src={inputIcon} alt="" />
        </span>
      </InputGroup.Text>}
      <div className='position-relative'>
        <FormControl
          onChange={(e) => {
            let value = e.target.value ? e.target.value : ""
            onChange(value)
          }}
          value={value}
          type={hideShowIcon ? passHide ? 'password' : 'text' : type}
          placeholder={placeholder ? placeholder : ""}
          aria-label={placeholder}
          aria-describedby="basic-addon1"
        />
      </div>
      {hideShowIcon ? passHide ? <EyeIcon onClick={() => setPassHide(false)} className="cursor-pointer position-absolute me-3 end-0 top-0 mt-3" />
        : <EyeSlashIcon onClick={() => setPassHide(true)} className="cursor-pointer position-absolute me-3 end-0 top-0 mt-3" />
        : ""}

    </InputGroup>
    {validateError && <span className="text-danger">{validateError} </span>}
  </div>);
}
