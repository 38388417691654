import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function NotificationLoader() {
    return (
        <SkeletonTheme
            baseColor="#e5e7eb"
            highlightColor="#e5e7eb"
        >
            <div className='px-2 mb-2'>
                <Skeleton style={{ height : "50px" }}/>
            </div>
        </SkeletonTheme>

    )
}
