import { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LoginButtonIcon from "../../../src/assets/Images/Icons/LoginButtonIcon.svg";
import LoginKeyIcon from "../../../src/assets/Images/Icons/LoginKeyIcon.svg";
import LoginUserIcon from "../../../src/assets/Images/Icons/LoginUserIcon.svg";
import { alerts } from "../../common/alertService";
import { commonHelper } from "../../common/commonHelper";
import AuthInputText from "../../Component/Form/AuthInputText";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";
import { useDispatch } from "react-redux";
import ButtonLoading from "../../Component/Form/ButtonLoading";

function AuthLogin() {

  document.title = process.env.REACT_APP_NAME + ' | ' + 'Login';

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const [formInputs, setFormInputs] = useState<any>({
    username: "",
    password: ""
  })

  const [formInputsErrors, setFormInputsErrors] = useState<any>({
    username: "",
    password: ""
  })

  const validationError = {
    username: "Username field is required.",
    password: "Password field is required.",
    validate_email: "Email is not valid",
  }
  const validateForm = () => {
    let errorJson = {};
    let isValidate = true;

    Object.keys(formInputsErrors).forEach((form_er_key) => {
      if (formInputs[form_er_key] == "") {
        errorJson = {
          ...errorJson,
          [form_er_key]: validationError[form_er_key]
        }
        isValidate = false;
      }
      if (form_er_key === "username") {
        if (formInputs[form_er_key] !== "") {
          if (!pattern.test(formInputs.username)) {
            errorJson = {
              ...errorJson,
              [form_er_key]: validationError.validate_email
            }
            isValidate = false;
          }
        }
      }
    })

    setFormInputsErrors(errorJson)
    return isValidate;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {

      setLoading(true)
      let res = await APICall({
        url: ApiTypes.login,
        data: formInputs
      })
      if (res) {
        if (res.status === 1) {
          commonHelper.setItem("auth_token", res.data.auth_token);
          commonHelper.setItem("user_id", res.data.user_id)
          dispatch({ type: 'USER_ID', payload: res.data.user_id })
          dispatch({ type: 'AUTH_TOKEN', payload: res.data.auth_token })
          let response = await APICall({
            url: ApiTypes.ProfileDetails
          })
          if (response) {
            let resRoles =  await APICall({url : ApiTypes.fetchUserRoles})
            if (response.status === 1 && resRoles.status === 1) {
              setLoading(false)
              commonHelper.setItem("user", JSON.stringify(response.data.profile));
              commonHelper.setItem("user_roles", JSON.stringify(resRoles.data));
              dispatch({ type: 'LOGGED_USER', payload: response.data.profile })
              dispatch({ type: 'USER_ROLES', payload: resRoles.data })
              alerts.notifySuccess("Logged in successfully.")
              navigate('/') 

            }
          } else {
            setLoading(false)
          }

        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
  }
  return (
    <>
      <div className="login-title">
        Sign in to
        <span className="d-block">
          your account <span className="three-dots">...</span>
        </span>
      </div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <AuthInputText
        value={formInputs.username}
          onChange={(value) => {
            setFormInputs({
              ...formInputs,
              username: value
            })
          }}
          inputIcon={LoginUserIcon}
          type="text"
          placeholder="Username"
          validateError={formInputsErrors.username}
        />
        <AuthInputText
        value={formInputs.password}
          onChange={(value) => {
            setFormInputs({
              ...formInputs,
              password: value
            })
          }}
          inputIcon={LoginKeyIcon}
          type="password"
          placeholder="Password"
          validateError={formInputsErrors.password}
        />
        <Form.Group className="form-button d-flex justify-content-between align-items-center">

          <ButtonLoading
            loading={loading}
            innerText={<><span className="me-3">
              <img src={LoginButtonIcon} alt="" />
            </span>
              <span>Login</span></>}
          />

          <Link className="forgot-link" to="/forgot-password">
            Forgot Password ?
          </Link>
        </Form.Group>
      </Form>
    </>
  );
}

export default AuthLogin;
