import React, { useEffect, useState } from 'react'
import DefaultImg from "../../assets/Images/Icons/no_image.png";
import NoBG from "../../assets/Images/Icons/no-bg.jpg";
import { commonHelper } from '../../common/commonHelper';

export default function PreviewImg({
    src,
    style='',
    title='',
    default_img='',
    alt='',
    width='',
    height=''
  }) {
  
    const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

    const defaultImages = [DefaultImg, NoBG];

    useEffect(() => {
        getImageUrls(src);
    }, [src]);

    async function getImageUrls(src) {

        if(src != ''){

            if(defaultImages.includes(src)){
                setImgPreviewUrl(src);
            }else{
                const signedUrl = await commonHelper.getAssetUrl(src);
                setImgPreviewUrl(signedUrl);
            }
            
        }else{
            if(default_img != ''){
                setImgPreviewUrl(default_img);
            }else{
                setImgPreviewUrl(DefaultImg);
            }
        }
        
    }

    return (<>
      <img className={style ? style : ''} src={imgPreviewUrl} alt={alt} width={width} height={height}></img>
    </>
    );
  }
  