import React from 'react'
import Unauthorized from "../../assets/Images/Icons/unauthorized.svg"

export default function Page_401() {
  return (<div className="text-center d-flex justify-content-center">
    <div className='mt-4'>
      <div className='h4 text-muted'>You are not authorized to access this.</div>
      <img height={'250px'} width={'250px'} src={Unauthorized}></img>
    </div>

  </div>

  )
}
