import "@szhsin/react-menu/dist/index.css";
import { useNavigate } from "react-router-dom";
import ProfileImg from "../../assets/Images/Header/profile-img.png";
import NotificationBell from "../../assets/Images/Icons/header-bell-black-icon.svg";
import PreviewImg from '../../Component/Form/PreviewImg';
import NoImage from "../../assets/Images/Icons/no_image.png";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { alerts } from "../../common/alertService";
import { Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import { commonHelper } from "../../common/commonHelper";
import NotificationLoader from "../Loader/NotificationLoader";

function TopMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedUser = useSelector((state: any) => state.data.logged_user)

  const [showNotification, setShowNotification] = useState(false)
  const wrapperRef = useRef<any>(null)
  const buttonRef = useRef<any>(null)


  const [notifications, setNotifications] = useState(null);


  const handleLogout = () => {
    localStorage.clear();
    dispatch({ type: 'USER_ID', payload: null })
    dispatch({ type: 'AUTH_TOKEN', payload: null })
    dispatch({ type: 'LOGGED_USER', payload: null })
    dispatch({ type: 'USER_ROLES', payload: null })
    alerts.notifySuccess("Logged out successfully.")
    navigate('/')
  }

  const onOutsideClick = () => {
    setShowNotification(false)
  }

  const handleClickOutside = (event) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        onOutsideClick();
      }
    }

  }

  const fetchNotifications = async () => {
    let res = await APICall({
      url: ApiTypes.unreadNotifications
    });
    if (res.status === 1) {
      setNotifications(res.data.list);
    }
  };

  useEffect(() => {
    fetchNotifications();
    let fetchNotInterval = setInterval(() => {
      fetchNotifications();
    }, 5000);
    return () => {
      clearInterval(fetchNotInterval);
    };
  }, []);


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [])


  return (
    <>
      <div className="right-section ms-auto">
        <div className="notification-col">
          <Button ref={buttonRef} onClick={() => {
            setShowNotification(!showNotification)

          }} variant="notification-btn-icon" type="button">
            <img src={NotificationBell} alt="NotificationBell" height={'28'} width={'28'} />
            {notifications != null ? (
              notifications.length > 0 ? (
                <span className="badge-up bg-danger rounded-pill">
                  {notifications.length}
                </span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Button>
          {showNotification && <div ref={wrapperRef} className="notification-list">
            <div className="notification-header">notifications</div>
            <div className="notifications">
              <div className="notifications-items">
                {notifications !== null ? (
                  notifications.length > 0 ?
                    notifications.map((notification, index) => {
                      return (
                        <div onClick={() => {
                          navigate("/notifications", { replace: true });
                          setShowNotification(false)
                        }} className="notifications-item d-flex">
                          {/* <div className="notifications-img me-3">
                        <img className="rounded-circle" src={ProfileImg} alt="NotificationBell" height={'40'} width={'40'} />
                        </div> */}
                          <div className="notifications-content-col w-100">
                            <div className="notifications-name">{notification.notification_title}</div>
                            <div className="notifications-content-bottom-col d-flex">
                              <div className="notifications-description me-2">{notification.notification_description}</div>
                              <div className="notifications-time ms-auto">{commonHelper.formatDate(notification.created_at, 'MM-DD-YYYY')}</div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    : (
                      <div className="text-center p-4">
                        No notifications!
                      </div>
                    )
                ) : (
                  <NotificationLoader />
                )}


              </div>
              <div onClick={() => {
                navigate('/notifications')
                setShowNotification(false)
              }} className="notification-all-bottom cursor-pointer text-muted border-top h4 text-center py-3 mb-0">Read all notifications</div>
            </div>
          </div>}
        </div>
        <div className="profile-section d-lg-flex align-items-lg-center">
          {["end"].map((direction, key) => (
            <Dropdown key={key}>
              <Dropdown.Toggle id={`dropdown-button-drop-${direction}`} variant="profile-button">
                <span className="user">

                  <PreviewImg style='rounded-circle obj_fit_img' alt="user" width="40" height="40" src={loggedUser ? loggedUser.profile_img ? process.env.REACT_APP_ASSETS_URL + loggedUser.profile_img : NoImage : NoImage} default_img={NoImage} />

                  {/* <img
                    src={loggedUser ? loggedUser.profile_img ? process.env.REACT_APP_ASSETS_URL +  loggedUser.profile_img :   NoImage :NoImage }
                    className="rounded-circle obj_fit_img"
                    alt="user"
                    width="40"
                    height="40"
                  /> */}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item href="#">Edit Profile</Dropdown.Item> */}
                <Dropdown.Item onClick={() => navigate('/edit/profile')}>Edit Profile</Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ))}
        </div>
      </div>
    </>
  );
}

export default TopMenu;
