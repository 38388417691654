import { Col, Row } from "react-bootstrap";
import LoginLogoImage from "../../../src/assets/Images/LoginPage/login-logo.svg";

function AuthLayout({ PageComponent }) {

  return (
    <div className="login-page">
      <div className="container-fluid p-0">
        <Row className="m-0">
          <Col lg="8" className="p-0">
            <div className="left-col col-xxl d-flex h-100 w-100">
              <div className="login-logo">
                <span>
                  <img src={LoginLogoImage} alt="Logo" title="Logo" />
                </span>
              </div>
            </div>
          </Col>
          <Col lg="4" className="p-0 ">
            <div className="right-col d-flex h-100 w-100"></div>
          </Col>
          <div className="login-section">
            <PageComponent />
          </div>
        </Row>
      </div>
    </div>
  );
}

export default AuthLayout;
