import React, { useEffect, useState } from 'react'
import { Modal, Form, Container, Row, Col } from "react-bootstrap";
import { commonHelper } from '../../../common/commonHelper';
import ButtonLoading from '../../../Component/Form/ButtonLoading';
import WhiteSaveIcon from "../../../assets/Images/Icons/whiteSaveIcon.svg";
import Loader from '../../../assets/Images/Icons/animation/loading.svg';
import APICall from '../../../networking/AxiousServices';
import ApiTypes from '../../../networking/APItypes';
import CustomSelect from '../../../Component/Form/CustomSelect';
import { alerts } from '../../../common/alertService';
import { Enums } from '../../../constants/Enums';

export default function StatusChangePopup({ fetchList , showPopup, claimId, claimStatus, setShowPopup, setClaimId, setClaimStatus }) {
    const role_right_id = commonHelper.getRightId("claim_profile");
    const header: any = {
        right_id: role_right_id,
        action: 'update'
    }
    const [loading, setLoading] = useState(false);

    const [selectedItem, setSelectedItem] = useState("")

    const [itemError, setItemError] = useState("")

    const closePopup = () => {
        // setArtistIdToAssign(null)
        // setShowAssignClientPopup(false)
        // setSelClienterror("")
        // setSelectedClient("")
        // setClients([])
        // setLoading(false)
        setShowPopup(false)
        setClaimId(null)
        setClaimStatus("")
        setLoading(false)
        setItemError("")
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        
        if (selectedItem === "") {
            setItemError("Please select a status.")
        } else {
            setLoading(true)
            let data: any = {
                claim_request_id : claimId,
                status : selectedItem
            }
            let res = await APICall({ url: ApiTypes.changeClaimStatus, data: data, header: header });
            if (res.status === 1) {
                setLoading(false)
                fetchList("" ,"" ,1)
                closePopup()
                alerts.notifySuccess(res.message)
            }
        }
    }

    useEffect(() => {
        setSelectedItem(claimStatus)
    }, [claimId])


    return (<Modal className="modal-popup" show={showPopup} onHide={closePopup}>
        <Modal.Header closeButton>
            <Modal.Title>Select Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container fluid={true} className="mt-4">
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <Row>
                        {/* <Col xs={12} className="mb-3 d-flex gap-2"> */}
                        <CustomSelect
                        validationClass={'custom-status-plan'}
                            mainDivClasses={'col-12 col-md-12 col-lg-12 mb-5 custom-main-select'}
                            defaultLable="Select Status"
                            onChange={(value) => {
                                setSelectedItem(value ? value : "")
                                //   setSelectedClient(value ? value :"")
                            }}
                            data={Enums.ClaimStatusOptions}
                            validateError={itemError !== "" ? itemError : ""}
                            selectedValue={selectedItem}
                        />
                        {/* </Col> */}
                        <Col xs={12} className="mb-3 d-flex gap-2">
                            <ButtonLoading
                                loading={loading}
                                innerText={<> <span className="me-2">
                                    <img src={WhiteSaveIcon} alt="" />
                                </span>
                                    Save
                                </>}
                            />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Modal.Body>
    </Modal>)
}
