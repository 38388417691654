import { useEffect, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoginButtonIcon from "../../../src/assets/Images/Icons/LoginButtonIcon.svg";
import LoginKeyIcon from "../../../src/assets/Images/Icons/LoginKeyIcon.svg";
import LoginUserIcon from "../../../src/assets/Images/Icons/LoginUserIcon.svg";
import { alerts } from "../../common/alertService";
import { commonHelper } from "../../common/commonHelper";
import AuthInputText from "../../Component/Form/AuthInputText";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";
import { useDispatch } from "react-redux";

export default function ResetPass() {

    document.title = process.env.REACT_APP_NAME + ' | ' + 'Reset Password';
    const params = useParams<any>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)
    const [buttonLoading, setButtonLoading] = useState(false)

    const [formInputs, setFormInputs] = useState<any>({
        password: "",
        confirm_password: ""
    })

    const [formInputsErrors, setFormInputsErrors] = useState<any>({
        password: "",
        confirm_password: ""
    })

    const validationError = {
        password: "Password field is required.",
        confirm_password: "Confirm Password field is required.",
        validate_password: "Password and confirm password must match.",
    }

    const validateForm = () => {
        let errorJson = {};
        let isValidate = true;
        Object.keys(formInputsErrors).forEach((form_er_key) => {
            if (formInputs[form_er_key] === "") {
                errorJson = {
                    ...errorJson,
                    [form_er_key]: validationError[form_er_key]
                }
                isValidate = false;
            }
        })
        if (isValidate === true) {
            if (formInputs.password !== formInputs.confirm_password) {
                errorJson = {
                    ...errorJson,
                    'confirm_password': validationError.validate_password
                }
                isValidate = false;
            }
        }
        setFormInputsErrors(errorJson)
        return isValidate;
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateForm()) {

            setButtonLoading(true)
            let data: any = {
                email_token: params.email_token,
                password: formInputs.password
            }
            let res = await APICall({ url: ApiTypes.resetPassword, data: data });
            if (res) {
                if (res.status == 1) {
                    setButtonLoading(false);
                    navigate('/')
                    alerts.notifySuccess(res.message)
                } else {
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }
        }
    }
    const checkResetToken = async () => {
        let data: any = { email_token: params.email_token };
        const res = await APICall(
            {
                url: ApiTypes.checkResetToken,
                data: data
            }
        )
        if (res) {
            if (res.status === 1) {
                setLoading(false)
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
            navigate('/')
        }
    }

    useEffect(() => {
        checkResetToken()
    }, []);

    if (!loading) {
        return (<>
            <div className="login-title">
                Reset Password
                {/* <span className="d-block">
                    Reset your account <span className="three-dots">...</span>
                </span> */}
            </div>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <AuthInputText
                    value={formInputs.password}
                    onChange={(value) => {
                        setFormInputs({
                            ...formInputs,
                            password: value
                        })
                    }}
                    inputIcon={LoginKeyIcon}
                    type="password"
                    placeholder="Password"
                    validateError={formInputsErrors.password}
                />
                <AuthInputText
                    value={formInputs.confirm_password}
                    onChange={(value) => {
                        setFormInputs({
                            ...formInputs,
                            confirm_password: value
                        })
                    }}
                    inputIcon={LoginKeyIcon}
                    type="password"
                    placeholder="Confirm Password"
                    validateError={formInputsErrors.confirm_password}
                />
                <Form.Group className="form-button d-flex justify-content-between align-items-center">
                    {!buttonLoading ? <Button type="submit" className="btn btn-primary">
                        <span className="me-3">
                            <img src={LoginButtonIcon} alt="" />
                        </span>
                        Reset
                    </Button> :
                        <Button type="button" className="btn btn-primary">
                            <Spinner size="sm" animation="border" /> <span className="ms-2">Loading...</span>
                        </Button>
                    }
                </Form.Group>
            </Form>
        </>)
    }
    else {
        return "";
    }
}
