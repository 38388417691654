import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import InputText from '../../../Component/Form/InputText';
import ApiTypes from '../../../networking/APItypes';
import APICall from '../../../networking/AxiousServices';
import Switch from "react-switch";
import { alerts } from '../../../common/alertService';
import TableLoader from '../../../Component/Loader/TableLoader';
import InPageNav from '../../Common/InPageNav/Index';
import { useNavigate } from 'react-router-dom';
import { commonHelper } from '../../../common/commonHelper';
import Page_401 from '../../../Component/ErrorPages/Page_401';
import NoImage from "../../../assets/Images/Icons/no_image.png";
import PreviewImg from '../../../Component/Form/PreviewImg';
import { Enums } from '../../../constants/Enums';
import StatusChangePopup from './StatusChangePopup';

export default function ClaimProfile() {


    document.title = process.env.REACT_APP_NAME + ' | ' + 'Claim Profile List';
    const userRights = commonHelper.checkRights('claim_profile')
    const role_right_id = commonHelper.getRightId('claim_profile');
    const header: any = {
      right_id: role_right_id,
      action: 'view'
    }
  
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState(1);
    const [perPageRecords, setPerPageRecords] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [list, setList] = useState({
      isData: false,
      data: [],
    });
    const [dataTableLoading, setDataTableLoading] = useState(true);
    const [txtSearch, setTxtSearch] = useState("");
  


    const [showPopup, setShowPopup] = useState(false)
    const [claimId, setClaimId] = useState(null)
    const [claimStatus, setClaimStatus] = useState("")
    
  
    React.useEffect(() => {
      fetchList("", "", 1);
    }, [pageNo, perPageRecords]);
  
    useEffect(() => {
      fetchList("", "", 1);
    }, [txtSearch]);
  
  
    const fetchList = async (
      column = "",
      sortDirection = "",
      isFilter = 0) => {
  
      let data: any = {
        page: pageNo,
        limit: perPageRecords,
        order: { column: column, dir: sortDirection },
        is_filter: isFilter,
        filter: {
          search_text: txtSearch
        }
      }
      let res = await APICall({
        url: ApiTypes.fetchClaimProfileList, data: data, header: header
      })
  
      if (res.status == 1) {
        setList({ isData: true, data: res.data.list })
        setTotalRows(res.data.total)
        setDataTableLoading(false);
  
      }
    }
  
    const handleSort = async (column, sortDirection) => {
      fetchList(column.shortField, sortDirection, 1);
    };
  
  
    const handlePerRowsChange = async (newPerPage, page) => {
      setPerPageRecords(newPerPage);
    };
  
    const handlePageChange = (page) => {
      setPageNo(page);
    };
  
    const handleChangeStatus = (e, claim_id, status) => {
        setShowPopup(true)
        setClaimId(claim_id)
        setClaimStatus(status)
    }

    const columns = [
      {
        name: "Client Name",
        selector: (row) => row.client_display_name ? row.client_display_name : "",
        sortable: true,
        sortField: "client_display_name",
      },
      {
        name: "Artist Name",
        selector: (row) => row.artist_display_name ? row.artist_display_name : "",
        sortable: true,
        sortField: "artist_display_name",
      },
      {
        name: "Request Date",
        selector: (row) => row.created_at ? commonHelper.formatDate(row.created_at, 'MM/DD/YYYY') : "",
        sortable: true,
        sortField: "created_at",
      },
      {
        name: "Action Date",
        selector: (row) => row.created_at ? commonHelper.formatDate(row.created_at, 'MM/DD/YYYY') : "",
        sortable: true,
        sortField: "created_at",
      },
      {
        name: "Status",
        selector: (row) => <>
       
            <span  className={`${Enums.ClaimStatusColor[row.status]} labal-color text-white`}>{row.status ? Enums.ClaimStatusColor[row.status] : ""}
            </span>
        </>,
        width: "110px",
        sortable: false,
      },
      {
        omit: userRights.is_update === 1 ? false : true,
        name: "Action",
        width: "80px",
        sortable: false,
        cell: (row) => <span>
          <i onClick={(e) => {
                    handleChangeStatus(e, row.claim_request_id, row.status)
                }}  className="fas fa-edit cursor-pointer"></i>
          {/* <i onClick={() => {
            navigate(`/view/client/${row.client_id}`)
          }} className="ms-2 cursor-pointer fas fa-eye"></i> */}
        </span>
      }
    ];
  
    const pageNav = [
      {
        name: 'Claim Profile List',
        link: '/claim_profile',
        active: true
      }
    ]
  
    useEffect(() => {
      fetchList("", "", 0)
    }, [])

    if (userRights.is_view === 1) {
        return (<>
          <Card className="p-4 mb-4">
            <InPageNav pageNav={pageNav} />
            <div className='container-fluid mb-3 p-0'>
              {/* <div className='row d-flex justify-content-between align-items-center'>
                <div className='col-4'>
                  <div className="h6">Client List</div>
                </div> */}
              {/* <div className='col-2 d-flex justify-content-end'>
                          <Button type="button" onClick={() => { navigate('/add/video') }} variant="success" >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                          </Button>
                      </div> */}
    
              {/* </div> */}
              <div className='search-col-top d-flex justify-content-end mb-4'>
                <InputText onChange={(value) => {
                  setTxtSearch(value)
                  if (value) {
                    setResetPaginationToggle(true)
                  } else {
                    setResetPaginationToggle(false)
                  }
                }} value={txtSearch} mainDivClasses="search-top" type="text" placeholder="Search" />
                {/* <div className='plus-icon-button ms-3'>
                  <Button type="button" onClick={() => { navigate('/add/video') }} variant="success" >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </Button>
                </div> */}
              </div>
    
              <div className="z-adjust-for-dropdown">
                <DataTable
                  columns={columns}
                  data={list.data}
                  progressPending={dataTableLoading}
                  progressComponent={<TableLoader columns={columns} />}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  onSort={handleSort}
                // actions={<>
                //   <div className="col-4">
                //     <InputText onChange={(value) => {
                //       if (value) {
                //         setTxtSearch(value)
                //       } else {
                //         setTxtSearch(value)
                //       }
                //     }} value={txtSearch} mainDivClasses="col-12 col-md-12 col-lg-12 mt-2" type="text" placeholder="Search" />
                //   </div>
                // </>}
                />
              </div>
            </div>
                
            <StatusChangePopup
                    showPopup={showPopup}
                    claimStatus={claimStatus}
                    claimId={claimId}
                    setShowPopup={setShowPopup}
                    setClaimId={setClaimId}
                    setClaimStatus={setClaimStatus}
                    fetchList={fetchList}
                />
            </Card></>)
      } else {
        return <Page_401 />
      }
}
